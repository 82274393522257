export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~6],
		"/admin": [~7,[2]],
		"/admin/artikler-tiny": [~9,[2]],
		"/admin/artikler": [~8,[2]],
		"/admin/db-funktioner": [~10,[2]],
		"/admin/edit-user/[id]": [~11,[2]],
		"/admin/hashtester": [12,[2]],
		"/admin/home-beskeder": [~13,[2]],
		"/admin/ingredienser": [~14,[2]],
		"/admin/kategorier": [~15,[2]],
		"/admin/kontaktformularen": [~16,[2]],
		"/admin/marketing": [~17,[2]],
		"/admin/opskrifter": [~18,[2]],
		"/admin/opskriftfotos": [~19,[2]],
		"/admin/producenter": [~20,[2]],
		"/admin/recipeimages": [~21,[2]],
		"/admin/settings": [~22,[2]],
		"/admin/stats": [23,[2]],
		"/admin/temaer": [~24,[2]],
		"/admin/testside": [25,[2]],
		"/admin/vaegttyper": [~26,[2]],
		"/artikler/[rubrik]": [~27],
		"/bruger-ingredienser": [~28],
		"/bruger-opskrifter": [~29],
		"/favoritter": [~30],
		"/fileuploadtest": [~31],
		"/follow": [~32],
		"/ingredienser/[kategori]/[[slug]]": [~35],
		"/ingrediensredigering": [~36],
		"/ingrediens/[id]/[slug]": [~34],
		"/ingr/[kategori]/[[slug]]": [~33],
		"/kategorier/[slug]/[[subkat]]": [~37],
		"/kontakter": [~39],
		"/kontakt": [~38],
		"/madfaka-gl": [~40],
		"/madfakta": [~41],
		"/mine-opskrifter": [~42],
		"/ny-opskrift/[[id]]": [~43],
		"/opskrifter/[slug]": [~45,[],[4]],
		"/opskrifter/[memberid]/[niceurl]": [~44,[],[3]],
		"/password-reset": [~46],
		"/plus-bruger": [~47],
		"/private-kategorier": [~48],
		"/privatlivsindstillinger": [~49],
		"/producenter/[slug]": [~50],
		"/profil": [~51],
		"/rediger-opskrift/[id]": [~52,[],[5]],
		"/search": [~53],
		"/signup": [~54],
		"/tema/[temanavn]": [~55]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';